<template>
  <v-layout wrap justify-center id="cf">
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <v-flex xs12 lg8 pt-6>
      <v-layout wrap>
        <v-flex xs12>
          <v-card
            class="mx-auto elevation-2 pa-6"
            max-width="1088px"
            align="center"
            outlined
          >
            <v-layout wrap>
              <v-flex
                xs12 lg4
                class="text-left nsbold"
                style="font-weight: 700; font-size: 20px"
              >
                <v-icon class="pr-2" color="#797979">mdi-account-plus</v-icon
                >Check out</v-flex
              >
            </v-layout>

            <v-layout wrap pt-6>
              <v-flex xs12 lg6 sm6>
                <v-flex xs12 text-left pl-4 pb-4>
                  <span class="text-left nsbold" style="font-size: 16px"
                    >Scan BarCode</span
                  >
                </v-flex>
                <v-layout wrap style="font-weight: 400">
                  <v-flex xs12>
                    <v-text-field
                      v-model="Qrcode"
                      dense
                      label="Scan BarCode"
                      outlined
                      autofocus
                      class="nsregular px-4"
                      id="outTqr"
                    ></v-text-field>
                    <!-- <v-text-field
                        outlined
                        label="Barcode"
                        dense
                        autofocus
                        class="nsregular px-4"
                        v-model="Qrcode"
                        placeholder="Click here"
                        @change="getData(Qrcode)"
                      ></v-text-field> -->
                  </v-flex>
                </v-layout>
                <v-layout
                  wrap
                  style="font-weight: 400"
                  v-if="student.kindofCloth"
                >
                  <v-flex xs12>
                    <v-text-field
                      v-model="student.kindofCloth"
                      dense
                      label="Category"
                      outlined
                      class="nsregular px-4"
                      disabled
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap style="font-weight: 400">
                  <v-flex xs12>
                    <v-text-field
                      v-model="stdata.admissionNo"
                      dense
                      label="Admission.No"
                      outlined
                      class="nsregular px-4"
                      disabled
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap style="font-weight: 400">
                  <v-flex xs12>
                    <v-text-field
                      v-model="stdata.name"
                      dense
                      label="Name"
                      outlined
                      class="nsregular px-4"
                      disabled
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap style="font-weight: 400">
                  <v-flex xs12 lg6>
                    <v-text-field
                      v-model="stdata.Class"
                      dense
                      label="Class"
                      outlined
                      class="nsregular px-4"
                      disabled
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 lg6>
                    <v-text-field
                      v-model="stdata.division"
                      dense
                      label="Division"
                      outlined
                      class="nsregular px-4"
                      disabled
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 lg6 sm6>
                <v-flex xs12 text-left>
                  <span class="text-left nsbold" style="font-size: 16px"
                    >Laundry In-Items of </span
                  ><span class="nsbold" style="color:#766BC0" v-if="stdata.name">{{ stdata.name }}</span>
                </v-flex>
                <v-flex xs12 class="text-left pt-4" v-if="clothes">
                  <v-card round color="#FAFAFA">
                    <v-layout wrap pa-2>
                      <v-flex xs4>Item</v-flex>
                      <v-flex xs3>Barcode Id</v-flex>
                      <v-flex xs5>Last In</v-flex>
                    </v-layout>
                  </v-card>
                  <v-card elevation="0">
                    <v-layout
                      wrap
                      pa-2
                      v-for="(item, i) in clothes"
                      :key="i"
                      class="nsregular"
                    >
                      <v-flex xs4 v-if="item.clothid.clothType">{{
                        item.clothid.clothType.name
                      }}</v-flex>
                      <v-flex xs3 v-if="item.clothid.QRcode">{{
                        item.clothid.QRcode
                      }}</v-flex>
                      <v-flex class="nsregular" xs5 v-if="item.update_date">
                          {{formatDate(item.update_date)}}
                        </v-flex>
                      <v-flex xs12 py-1>
                        <v-divider></v-divider>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>

                <v-flex xs12 align-self-center text-center pa-4 v-else>
                  <span class="nsregular">Currently no item found!</span>
                </v-flex>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>

      <v-snackbar
        :timeout="3000"
        :value="true"
        right
        color="red"
        v-model="snackbar"
      >
        <span style="font-size: 20px"> {{ msg }}</span>
      </v-snackbar>
      <v-snackbar
        :timeout="3000"
        :value="true"
        right
        top
        color="success"
        v-model="snackbar1"
      >
        <v-layout align-center pr-4 wrap>
          <v-flex xs3>
            <v-img
              height="45px"
              contain
              :src="require('./../../assets/images/success.png')"
            ></v-img>
          </v-flex>
          <v-flex xs6>
            <span style="font-size: 20px"> {{ msg }}</span>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-snackbar
        :timeout="3000"
        :value="true"
        right
        top
        color="red"
        v-model="snackbar2"
      >
        <v-layout align-center pr-4 wrap>
          <v-flex xs3>
            <v-img
              height="45px"
              contain
              :src="require('./../../assets/images/close.png')"
            ></v-img>
          </v-flex>
          <v-flex xs9>
            <span style="font-size: 20px"> {{ msg }}</span>
          </v-flex>
        </v-layout>
      </v-snackbar>
    </v-flex>
  </v-layout>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      student: [], //incoming data frm backend
      stdata: [],
      snackbar: false,
      snackbar1: false,
      snackbar2: false,
      appLoading: false,
      Qrcode: null,
      status: null,
      ServerError: false,
      msg: null,
      curStatus: "OUT",
      tqr: null,
      tqr2: null,
      clothes: null,
      clrdata: null,
      flag: true,
      str: [],
      watchAr: [],
      rnd: "flager",
      tempQR:null,
    };
  },
  watch: {
    // tqr() {
    //   console.log("got it =",this.tqr)
    //   this.str.push(this.tqr)
    //   // this.callData(this.tqr)
    //   console.log("Str", this.str)
    // }
    watchAr() {
      if (this.watchAr.length == 1) this.getData(this.tempQR);
      else if (this.watchAr.length > 1) {
        this.watchAr = [];
        this.getData(this.tempQR);
      }
    },
  },
  beforeMount() {
    this.testBarcode();
  },
  methods: {
    testBarcode() {
      var barcode = "";
      var interval = "";
      var vm = this;
      vm.QR = null;

      document.addEventListener("keydown", function (evt) {
        if (interval) clearInterval(interval);
        if (evt.code == "Enter") {
          if (barcode) vm.handleBarcode(barcode);
          barcode = "";
          return;
        }

        if (evt.code != "Shift") barcode += evt.key;
        interval = setInterval(() => (barcode = ""), 20);
      });
    },

    handleBarcode(scanned_barcode) {
      if (this.$route.name == "outItem") {
        this.watchAr.push(this.rnd);
        document.querySelector("#outTqr").innerHTML = scanned_barcode;
        // this.QR = scanned_barcode;
        // this.tqr = scanned_barcode;
        this.Qrcode = scanned_barcode;
        this.tempQR=scanned_barcode
        // this.getData(scanned_barcode);

        // document.querySelector("#tqr").innerHTML = scanned_barcode;
      }
    },

    clearall() {
      this.tqr = null;
      this.Qrcode = null;
      this.student = {};
      this.stdata = {};
      this.clothes = null;
    },
    async getData(myqr) {
      if (this.flag) {
        await this.callData(myqr);
        this.watchAr = [];
        this.tempQR = null;
      }
    },
    callData(myqr) {
      this.flag = false;

      var text= myqr
      myqr = text.replace("Shift","") 
      this.Qrcode=myqr
      this.student = [];
      this.stdata = [];
      this.clothes = [];
      this.appLoading = true;

      axios({
        method: "post",
        url: "/cloth/washing/in",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          QR: myqr,
          presentStatus: this.curStatus,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.flag = true;
          if (response.data.status == true) {
            this.student = response.data.cloth;
            this.stdata = response.data.cloth.studentid;
            this.clothes = response.data.presentcloth;
            // this.clrdata = response.data;
            this.msg = response.data.msg;

            this.snackbar1 = true;
          } else {
            this.clothes = response.data.presentcloth;
            if (response.data.cloth) {
              if (response.data.cloth.studentid) {
                this.stdata = response.data.cloth.studentid;
              }
            }

            this.msg = response.data.msg;
            this.snackbar2 = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.ServerError = true;
          this.appLoading = false;
        });
    },
     formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
    show() {
      this.content = this.student;
    },
  },
};
//v-for="(item, i) in cloth" :key="i"
</script>